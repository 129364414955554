/*
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 
 */
import Vue from 'vue'
import Router from 'vue-router'

const index = r => require.ensure([], () => r(require('@/pages/home/index.vue')), 'chunkname-index');

Vue.use(Router)
const router = new Router({
  mode: '',
  routes: [
    {path: '/', redirect: '/index'},
    {
      path: '/index',
      name: 'index',
      component: index
    },
    {
      path: '/service',
      name: 'service',
      component: () => import('@/pages/service/index.vue')
    },
    {
      path: '/channel',
      name: 'channel',
      component: () => import('@/pages/channel/index.vue')
    },
    {
      path: '/article',
      name: 'article',
      component: () => import('@/pages/channel/article.vue')
    },
    
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import('@/pages/aboutUs/index.vue')
    },
  ]
})

// 全局路由守卫
router.afterEach((to, from, next) => {
  // console.log( to )

  document.documentElement.scrollTop = 0; 
  document.body.scrollTop = 0; 
  
});

export default router

