
/****
 * 锚点跳转
 * 
 * @author yangjinlai 20220325
 */

 export default function(configString) {

    console.log( 'configString', configString)

    let id='anchor_'+configString;
    let anchor = document.getElementById(id);

    if( !!anchor){
        let go = anchor.offsetTop;
        document.documentElement.scrollTop = go; 
        document.body.scrollTop = go; 
    }
    
 }
