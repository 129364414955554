
(function flexible(window, document) {
  // 获取的html 的根元素
  var docEl = document.documentElement;
  // dpr 物理像素比
  var dpr = window.devicePixelRatio || 1;

  var isIOS = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Safari/605.1.15
  // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36
  console.log("-----isIOS-----",isIOS)
  // if(isIOS){
  //   dpr = isIOS ? Math.min(win.devicePixelRatio, 3) : 1;
  // }
        // dpr = isIOS ? Math.min(win.devicePixelRatio, 3) : 1,
 
  // adjust body font size    设置body 的字体大小
  function setBodyFontSize() {
    // 如果页面中有body 这一个元素，就设置body大小
    if (document.body) {
      document.body.style.fontSize = 12 * dpr + "px";
    } else {
      // 如果页面中没有body 这个元素，则等页面中主要的DOM元素加载完毕后，再设置body的字体大小
      document.addEventListener("DOMContentLoaded", setBodyFontSize);
    }
  }
  setBodyFontSize();
  console.log("----docEl.clientWidth-----",docEl.clientWidth)
  // set 1rem = viewWidth / 10    设置html 元素的字体大小
  function setRemUnit() {
    // if(isIOS){
    //   return
    // }
    // rem  把html分成24等份
    var rem = docEl.clientWidth / 14;
    docEl.style.fontSize = rem + "px";
    docEl.style.maxWidth = docEl.clientWidth + 'px'
    if(isIOS){
      return
    }else{
      docEl.style.width = 14 + 'rem'
    }
    // console.log("33333333333",docEl.clientWidth, rem)
  }
 
  setRemUnit();
 
  // reset rem unit on page resize    当页面尺寸大小发生变化的时候，要重新设置一下rem 的大小
  window.addEventListener("resize", setRemUnit);
  // pageshow 重新加载页面触发的事件
  window.addEventListener("pageshow", function(e) {
    // e.persisted 返回的事true 就是说如果这个页面事从缓存取过来的页面，也需要重新计算一下rem 的大小
    if (e.persisted) {
      setRemUnit();
    }
  });
 
  // detect 0.5px supports    用来处理有些移动端的浏览器不支持0.5像素的写法
  if (dpr >= 2) {
    var fakeBody = document.createElement("body");
    var testElement = document.createElement("div");
    testElement.style.border = ".5px solid transparent";
    fakeBody.appendChild(testElement);
    docEl.appendChild(fakeBody);
    if (testElement.offsetHeight === 1) {
      docEl.classList.add("hairlines");
    }
    docEl.removeChild(fakeBody);
  }
  
})(window, document);