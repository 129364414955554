/*
 * @Author: diaowangtao
 * @Date: 2020-08-21 17:53:14
 * @Description: vuex
 */
import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  state: {
    //存放的键值对就是所要管理的状态
    articleActive: -1,
        point: null,
        page: 0,
        language: 'tc',
   
    error: 0
  },
  mutations: {
    updateArticleActive(state, i) {
      state.articleActive = i
    },
    addPoint(state, i) {
      state.point = i
    },
    changePage(state, i) {
      state.page = i
    },
    changeLanguage(state, i) {
      state.language = i
    },
    changeError(state, i) {
      state.error = i
    }
  }
})

export default store
