/*
 * @Author: diaowangtao
 * @Date: 2020-08-25 14:24:14
 * @Description: 
 */
export default {
    header: {
        companyIntro: '公司介绍',
        

    },
    
}