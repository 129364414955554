import tcJson from '@/lang/tcJson.js'
import zhJson from '@/lang/zhJson.js'
export default function(content, lang) {
  let str = ''
  if (!content) return ''
  if (lang === 'zh') {
    for (let el of content) {
      if (tcJson.indexOf(el) > -1) {
        str += zhJson.charAt(tcJson.indexOf(el))
      } else {
        str += el
      }
    }
  } else {
    for (let el of content) {
      if (zhJson.indexOf(el) > -1) {
        str += tcJson.charAt(zhJson.indexOf(el))
      } else {
        str += el
      }
    }
  }
  return str
}
